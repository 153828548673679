/*

    script for side menu

*/
window.addEventListener('DOMContentLoaded', event => {
    const sidebarWrapper = document.getElementsByClassName('calli-template-mobile-navi')[0];

    if (sidebarWrapper){
        sidebarWrapper.addEventListener('click', event => {
            sidebarWrapper.classList.toggle('active');
            document.body.classList.toggle('calli-no-overflow');
            toggleMenuIcon();
        });
    
        // Closes the sidebar menu
        const menuToggle = document.body.querySelector('.calli-menu-toggle');
        menuToggle.addEventListener('click', event => {
            event.preventDefault();
            sidebarWrapper.classList.toggle('active');
            sidebarWrapper.focus();
            document.body.classList.toggle('calli-no-overflow');
            toggleMenuIcon();
        })
    
    
        function toggleMenuIcon() {
            if ( menuToggle.classList.contains('dashicons-menu') ) {
                menuToggle.classList.remove('dashicons-menu');
                menuToggle.classList.add('dashicons-no-alt');
            }
            else{
                menuToggle.classList.remove('dashicons-no-alt');
                menuToggle.classList.add('dashicons-menu');
            }  // if-else
        }  // _toggleMenuIcon
    }  // if
})